import { hyphenatedName } from '../format'

export const getInfluencerDetails = function (influencer) {
    const { user_screen_name, user_name, display_name, profile_image, description } = influencer

    let details = {}
    details.name = display_name? display_name: user_name
    details.hyphen_name = hyphenatedName(details.name)
    details.screen_name = user_screen_name
    details.description = description

    if (profile_image !== null && profile_image !== undefined)
        details.profile_image = profile_image.replace("_normal","")
    else
        details.profile_image = ''
        
    return details    
}