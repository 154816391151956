import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Review from "../../components/review"
import Book from "../../components/book"
import SameCategoryBooks from "../../components/samecategorybooks"

import { ProfileT } from "../../services/seotemplate"
import { fillTemplate, formatName } from "../../services/format"

const Profile = ({ data }) => {
    const { nodes:recos } = data.BookRecos ? data.BookRecos : {nodes: []}
    const { nodes:subCategoryBooks } = data.BooksInSubCategory ? data.BooksInSubCategory : {nodes: []}
    const { nodes:categoryBooks } = data.BooksInCategory ? data.BooksInCategory : {nodes: []}

    const book = recos[0].bookObjects[0]
    
    const categoryName = book.categoryMapping? book.categoryMapping.displayName : 
    book.genreMapping ?  book.genreMapping.displayName : null

    const templateVars = {}
    let title, description, booksincategory = null

    templateVars.author = formatName(book.author)
    templateVars.title = book.title

    if(book.author !== null){
      title = fillTemplate(ProfileT.title, templateVars)
      description = fillTemplate(ProfileT.description, templateVars)
    } else {
      title = fillTemplate(ProfileT.short_title, templateVars)
      description = fillTemplate(ProfileT.short_description, templateVars)
    }

    if(subCategoryBooks.length > 0)
      booksincategory = subCategoryBooks
    else if(categoryBooks.length > 0)
      booksincategory = categoryBooks 

    const schema = 
    {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": title,
      "description": description,
      "primaryImageOfPage":{"thumbnail":book.thumbnail}
    }  

    return(
        <Layout>
          <SEO title={title} description={description} image={book.thumbnail} schemaMarkup={schema}/>
          <Book book={book}/>
          <h1 style={{marginBottom:'15px'}}>Book Reviews</h1>
          {recos.map(reco => {
            return(
              <ul style={{ margin:"0px 15px 0px 15px" }}> 
                <li
                key={reco.id}
                style={{
                    textAlign: "center",
                    listStyle: "none",
                    display: "block"
                }}
                >
                    <Review reco={reco}/>
                </li>
              </ul>  
            )
          })}
          {book.description ? 
          <div style={{marginTop:'2rem'}}>
            <h1 style={{marginBottom:'15px'}}>About Book</h1>
            <div style={{ marginBottom:'20px', fontSize:'1.25rem'}}>
              {book.description}
            </div>
          </div> : null }
          {categoryName && categoryName.trim().length !==0 && booksincategory ? <SameCategoryBooks books={booksincategory} category={categoryName}></SameCategoryBooks>: null}
        </Layout>
    )
}

export const query = graphql`
  query($title: String!, $author: String, $bcCategory: String, $category: String, $bcSkipCount: Int, $skipCount: Int, $limit: Int) 
  {
    BookRecos:allReco(filter: {bookObjects: {elemMatch: {title: {eq: $title}, author: {eq: $author}}}}, sort: {fields: created_at, order: DESC}) {
      nodes {
          id
          tweet_id
          text
          ...BookFragment
          influencer {
              display_name
              user_name
              user_screen_name
              profile_image
          }
      }
    }
    BooksInSubCategory: allReco(filter: {bookObjects: {elemMatch: {title:{ne: $title},thumbnail: {ne: null}, categoryMapping: {displayName:{eq: $bcCategory}}}}}, skip:$bcSkipCount, limit:$limit) {
      nodes {
        bookObjects {
          _id
          title
          thumbnail
        }
      }
    }
    BooksInCategory: allReco(filter: {bookObjects: {elemMatch: {title:{ne: $title},thumbnail: {ne: null}, categories: {in: [$category]}}}}, skip:$skipCount, limit:$limit) {
      nodes {
        bookObjects {
          _id
          title
          thumbnail
        }
      }
    }  
  }
  `


export default Profile