import React from 'react'
import { Link } from 'gatsby'
import { Row, Col, Image } from 'react-bootstrap'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { getInfluencerDetails } from '../../services/influencerdetails'

import './style.css'

function Review({reco}){
    const { name, hyphen_name, screen_name, profile_image } = getInfluencerDetails(reco.influencer)
    return (
        <Row className="recos">
            <Col>
                <Row>
                    <Col style={{padding:0, maxWidth:"5rem"}}>
                        <Link to={"/books/" + hyphen_name}>
                            <LazyLoadComponent>
                                <Image src={profile_image} className="photo-thumbnail" alt={name} roundedCircle />
                            </LazyLoadComponent>
                        </Link>
                    </Col>
                    <Col className="recommender">
                        <span>
                            <Link to={"/books/" + hyphen_name}>{name}</Link>
                        </span>
                    </Col>
                </Row> 
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} style={{paddingLeft:"5px"}}>
                        <span className="text">{reco.text}</span> 
                        <span className="recolink">
                            <a href={"https://twitter.com/" + screen_name + "/status/" + reco.tweet_id} target="_blank" rel="noreferrer">Link to Tweet</a>
                        </span>
                    </Col>
                </Row>
            </Col>
        </Row>    
    )
}

export default Review;