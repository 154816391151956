import React from 'react'
import { Image, CardColumns, Card } from 'react-bootstrap'
import { Link } from 'gatsby'

import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { hyphenatedName } from '../../services/format'

import './style.css'

function SameCategoryBooks({books, category}){
    let book_list = []

    books.map((book) => book_list.push(book.bookObjects[0]))
    const unique_books = book_list.filter((v,i,a)=>a.findIndex(t=>(t.title === v.title))===i)

    return(
        <React.Fragment>
            <h1 style={{marginTop:"40px", marginBottom:"35px"}}>More Books in {category} </h1>
            <CardColumns className="bookcards">
                <ul style={{ padding: 0, marginTop: 0}}>
                    {unique_books.map( book => {
                        let recoLink = '/recos/' + hyphenatedName(book.title)
                        if(book.thumbnail == null)
                            return null
                    return(
                            <li
                            key={book._id}
                            style={{
                                textAlign: "center",
                                listStyle: "none",
                                display: "inline"
                            }}
                            >
                                <Card className="no-border">
                                    <Card.Body style={{padding:0, marginBottom:"1rem"}}>
                                        <Card.Text style={{marginBottom: "5px"}}>
                                        <Link to={recoLink}>
                                            <LazyLoadComponent>
                                                <Image src={book.thumbnail} className="book-image" alt={book.title} rounded fluid/>
                                            </LazyLoadComponent>
                                        </Link>
                                        </Card.Text>
                                        <Card.Text className="book-name">
                                            <Link to={recoLink} target="_blank" style={{display:"block", fontSize:"1.25rem"}}>
                                                {book.title}
                                            </Link>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </li>
                    )}
                )}
                </ul>
            </CardColumns>
        </React.Fragment>
    )
}

export default SameCategoryBooks